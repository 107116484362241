<template>
	<div>
		<el-form :model="formData" label-width="150px" ref="form">
<!--			{{formData}}-->
			<el-form-item label="是否移交领导工作">
				<one-checkbox v-model="formData.move_lingdao">是</one-checkbox>
			</el-form-item>
			<el-form-item label="" v-if="formData.move_lingdao === 1">
						<el-form-item label="原领导" label-width="100px" style="display: inline-block;" prop="from_lingdao_uid" :rules="[{required:true,message:'原领导必选'}]">
							<type-select url="/Dept/getDeptAllPerson" :is-top="false" clearable :multiple="false" filterable v-model="formData.from_lingdao_uid" :data="{type:'lingdao',dept_id:formData.dept_id}" style="width: 120px;" :disabledList="[formData.to_lingdao_uid]"></type-select>
						</el-form-item>
						<el-form-item label="目标领导" label-width="100px" style="display: inline-block;" prop="to_lingdao_uid" :rules="[{required:true,message:'目标领导必选'}]">
							<type-select clearable v-model="formData.to_lingdao_uid" url="/User/listAll" :data="{state:1,delete_time:0,orderBy:'create_time',sortBy:'desc',job:'lingdao',dept_id:formData.dept_id}" :disabledList="[formData.from_lingdao_uid]" filterable :is-top="false" :multiple="false" style="width: 120px;"></type-select>
						</el-form-item>
			</el-form-item>
			<el-form-item label="是否移交联络员工作">
				<one-checkbox v-model="formData.move_bsy">是</one-checkbox>
			</el-form-item>
			<el-form-item label="" v-if="formData.move_bsy === 1">
				<el-form-item label="原联络员" label-width="100px" style="display: inline-block;" prop="from_bsy_uid" :rules="[{required:true,message:'原联络员必选'}]">
					<type-select url="/Dept/getDeptAllPerson" :is-top="false" clearable :multiple="false" filterable v-model="formData.from_bsy_uid" :data="{type:'bsy',dept_id:formData.dept_id}" style="width: 120px;" :disabledList="[formData.to_bsy_uid]"></type-select>
				</el-form-item>
				<el-form-item label="目标联络员" label-width="100px" style="display: inline-block;" prop="to_bsy_uid" :rules="[{required:true,message:'目标联络员必选'}]">
					<type-select clearable v-model="formData.to_bsy_uid" url="/User/listAll" :data="{state:1,delete_time:0,orderBy:'create_time',sortBy:'desc',job:'bsy',dept_id:formData.dept_id}" :disabledList="[formData.from_bsy_uid]" filterable :is-top="false" :multiple="false" style="width: 120px;"></type-select>
				</el-form-item>
			</el-form-item>
			<el-form-item label="是否移交督办员工作">
				<one-checkbox v-model="formData.move_dby">是</one-checkbox>
			</el-form-item>
			<el-form-item label="" v-if="formData.move_dby === 1">
				<el-form-item label="原督办员" label-width="100px" style="display: inline-block;" prop="from_dby_uid" :rules="[{required:true,message:'原督办员必选'}]">
					<type-select url="/Dept/getDeptAllPerson" :is-top="false" clearable :multiple="false" filterable v-model="formData.from_dby_uid" :data="{type:'dby',dept_id:formData.dept_id}" style="width: 120px;" :disabledList="[formData.to_dby_uid]"></type-select>
				</el-form-item>
				<el-form-item label="目标督办员" label-width="100px" style="display: inline-block;" prop="to_dby_uid" :rules="[{required:true,message:'目标督办员必选'}]">
					<type-select clearable v-model="formData.to_dby_uid" url="/User/listAll" :data="{state:1,delete_time:0,orderBy:'create_time',sortBy:'desc',job:'dby',dept_id:formData.dept_id}" :disabledList="[formData.from_dby_uid]" filterable :is-top="false" :multiple="false" style="width: 120px;"></type-select>
				</el-form-item>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
export default {
	name: "detail",
	data() {
		return {
			formData:{
				move_lingdao:0,
				move_bsy:0,
				move_dby:0,
				from_lingdao_uid:'',
				to_lingdao_uid:'',
				from_bsy_uid:'',
				to_bsy_uid:'',
				from_dby_uid:'',
				to_dby_uid:'',
				dept_id:''
			},
		}
	},
	props:{
		datas:{
			type:[Array,Object],
			default(){
				return {}
			}
		}
	},
	created() {
		this.formData.dept_id = this.datas.dept_id ? this.datas.dept_id : ''
	},
	mounted() {
	},
	methods: {
		submit(){
			let _this = this;
			this.$refs['form'].validate((valid) => {
				if (valid) {
					_this.$http.request({
						url:'/Dept/movePerson',
						datas:_this.formData,
						success(res){
							_this.$message.success('操作成功');
							_this.$emit('success');
						}
					})
				}
			})
		}
	}
}
</script>
